
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";

interface Item {
  key: string;
  value: number;
}

@Options({
  props: {
    items: Object as PropType<Item[]>,
  },
})
export default class TotalComponent extends Vue {
  items!: Item[];
}
