
import { Options, Vue } from "vue-class-component";
import StatisticComponent from "./components/Statistic.vue";

@Options({
  components: {
    StatisticComponent,
  },
})
export default class App extends Vue {}
