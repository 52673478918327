export enum Bidders {
  Sape = 1,
  SegmentoKintavr = 2,
  SegmentoAdcamp = 3,
  MTS = 4,
  OTM = 5,
  Between = 6,
  BidvolBanner = 7,
  BidvolVideo = 10,
  BuzzoolaBanner = 8,
  BuzzoolaVideo = 11,
  Astralab = 9,
}
