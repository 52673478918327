import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"text-align":"right"},
  class: "d-flex pa-4"
}
const _hoisted_2 = {
  style: {"cursor":"pointer"},
  title: "Download"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApiKeyDialog = _resolveComponent("ApiKeyDialog")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Total = _resolveComponent("Total")!
  const _component_VDataTable = _resolveComponent("VDataTable")!
  const _component_download_csv = _resolveComponent("download-csv")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { style: {"margin-left":"10px","width":"1024px","margin-bottom":"20px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_ApiKeyDialog)
      ]),
      _: 1
    }),
    _createVNode(_component_v_card, {
      style: {"margin-left":"10px","width":"1024px"},
      variant: "tonal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              chips: "",
              multiple: "",
              label: "Select Metrics",
              items: _ctx.fieldNames,
              modelValue: _ctx.metrics,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.metrics) = $event))
            }, null, 8, ["items", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              chips: "",
              multiple: "",
              label: "Group By",
              items: _ctx.groupByNames,
              modelValue: _ctx.groupBy,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.groupBy) = $event))
            }, null, 8, ["items", "modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_card, { style: {"margin-left":"10px","width":"1024px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Currencies",
              modelValue: _ctx.currency,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currency) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_label, { text: "Date From" }),
            _createVNode(_component_Datepicker, {
              modelValue: _ctx.dateFrom,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dateFrom) = $event)),
              "enable-time-picker": false,
              format: "yyyy-MM-dd"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_label, { text: "Date To" }),
            _createVNode(_component_Datepicker, {
              modelValue: _ctx.dateTo,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dateTo) = $event)),
              "enable-time-picker": false,
              format: "yyyy-MM-dd"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Country Include",
              modelValue: _ctx.countryInclude,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.countryInclude) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Country Exclude",
              modelValue: _ctx.countryExclude,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.countryExclude) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "City Exclude",
              modelValue: _ctx.cityExclude,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.cityExclude) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "City Include",
              modelValue: _ctx.cityInclude,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.cityInclude) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              chips: "",
              multiple: "",
              label: "Impression Types",
              modelValue: _ctx.impressionTypes,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.impressionTypes) = $event)),
              items: ['banner', 'video', 'audio', 'native']
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              chips: "",
              multiple: "",
              label: "Publisher Exclude",
              modelValue: _ctx.publisherExclude,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.publisherExclude) = $event)),
              type: "number",
              items: _ctx.publishers,
              "item-title": "name",
              "item-value": "value"
            }, null, 8, ["modelValue", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              chips: "",
              multiple: "",
              label: "Publisher Include",
              modelValue: _ctx.publisherInclude,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.publisherInclude) = $event)),
              type: "number",
              items: _ctx.publishers,
              "item-title": "name",
              "item-value": "value"
            }, null, 8, ["modelValue", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Places Include",
              modelValue: _ctx.placeInclude,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.placeInclude) = $event)),
              type: "number"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Browser",
              modelValue: _ctx.browser,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.browser) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Device Brand",
              modelValue: _ctx.deviceBrand,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.deviceBrand) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Device Model",
              modelValue: _ctx.deviceModel,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.deviceModel) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "OS",
              modelValue: _ctx.os,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.os) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Visitor ID",
              modelValue: _ctx.visitorId,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.visitorId) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_combobox, {
              chips: "",
              multiple: "",
              label: "Visitor IP",
              modelValue: _ctx.visitorIp,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.visitorIp) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              chips: "",
              multiple: "",
              label: "Winner Bidder ID",
              modelValue: _ctx.winnerBidderId,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.winnerBidderId) = $event)),
              type: "number",
              items: _ctx.bidders,
              "item-title": "name",
              "item-value": "value"
            }, null, 8, ["modelValue", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_v_btn, {
                variant: "outlined",
                onClick: _ctx.clear
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Clear")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                variant: "outlined",
                onClick: _ctx.loadReport
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Load")
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: "fa-solid fa-spinner",
                    spin: "",
                    size: "2xl"
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_card, { style: {"margin-left":"10px","width":"1024px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_Total, { items: _ctx.totalItems }, null, 8, ["items"]),
        _createVNode(_component_VDataTable, {
          headers: _ctx.headers,
          items: _ctx.items
        }, null, 8, ["headers", "items"]),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_download_csv, { data: _ctx.items }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "fa-solid fa-download",
                size: "2xl"
              })
            ]),
            _: 1
          }, 8, ["data"]), [
            [_vShow, _ctx.items.length > 0]
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}