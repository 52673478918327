import { FieldNames } from "./fieldNames";

export const GroupByNames = [
  FieldNames.CreatedAt,
  FieldNames.CreatedDate,
  FieldNames.Currency,
  FieldNames.DomainChain,
  FieldNames.GeoCity,
  FieldNames.GeoCountry,
  FieldNames.Height,
  FieldNames.ImpressionType,
  FieldNames.PublisherDomain,
  FieldNames.Publisher,
  FieldNames.PublisherPlaceId,
  FieldNames.UserAgentBrowserFamily,
  FieldNames.UserAgentBrowserVersion,
  FieldNames.UserAgentBrowserVersionMajor,
  FieldNames.UserAgentDeviceBrand,
  FieldNames.UserAgentDeviceFamily,
  FieldNames.UserAgentDeviceModel,
  FieldNames.UserAgentOsFamily,
  FieldNames.UserAgentOsVersion,
  FieldNames.UserAgentOsVersionMajor,
  FieldNames.Visibility,
  FieldNames.VisitorId,
  FieldNames.VisitorIp,
  FieldNames.Width,
  FieldNames.WinnerBidder,
  FieldNames.WinnerBidderUserId,
];
