import { createApp } from "vue";
import App from "./App.vue";
import "material-design-icons-iconfont/dist/material-design-icons.css";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinner, faDownload } from "@fortawesome/free-solid-svg-icons";

import Toaster from "./services/toaster";
import JsonCSV from "@/services/csv";

const vuetify = createVuetify({
  components,
  directives,
});

library.add(faSpinner, faDownload);

createApp(App)
  .use(vuetify)
  .use(Toaster)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("download-csv", JsonCSV)
  .mount("#app");
