import * as api from "statistic-reader-client";
import { FieldNames as fieldNames } from "@/enums/fieldNames";
import { FilterNames } from "@/enums/filterNames";

export class API {
  private static getConfig(): api.Configuration {
    const params: api.ConfigurationParameters = {
      headers: {
        "X-API-Key": localStorage.getItem("apiKey") ?? "",
      },
    };

    const config = new api.Configuration(params);
    return config;
  }

  static loadReport(
    select: Array<fieldNames>,
    groupBy: Array<fieldNames>,
    filter: Map<FilterNames, string[] | string | number[] | number>
  ): Promise<api.OpenrtbStat[]> {
    const cfg = this.getConfig();
    const clt = new api.ReaderApi(cfg);
    const params: api.RequestsPostRequest = {
      request: {
        fieldsSelector: {
          acceptsInvitation: select.includes(fieldNames.AcceptInvitation),
          bidderRequests: select.includes(fieldNames.BidderRequests),
          clicks: select.includes(fieldNames.Clicks),
          clientRequests: select.includes(fieldNames.ClientRequests),
          closes: select.includes(fieldNames.Closes),
          collapses: select.includes(fieldNames.Collapses),
          completes: select.includes(fieldNames.Completes),
          createdAt: select.includes(fieldNames.CreatedAt),
          createdDate: select.includes(fieldNames.CreatedDate),
          currency: select.includes(fieldNames.Currency),
          domainChain: select.includes(fieldNames.DomainChain),
          exitsFullscrine: select.includes(fieldNames.ExitsFullscrine),
          expands: select.includes(fieldNames.Expands),
          firstQuartiles: select.includes(fieldNames.FirstQuartiles),
          fullscrines: select.includes(fieldNames.Fullscrines),
          geo: {
            city: select.includes(fieldNames.GeoCity),
            country: select.includes(fieldNames.GeoCountry),
          },
          height: select.includes(fieldNames.Height),
          impressionType: select.includes(fieldNames.ImpressionType),
          midpoints: select.includes(fieldNames.Midpoints),
          mutes: select.includes(fieldNames.Mutes),
          pauses: select.includes(fieldNames.Pauses),
          publisher: {
            domain: select.includes(fieldNames.PublisherDomain),
            id: select.includes(fieldNames.Publisher),
            place: select.includes(fieldNames.PublisherPlaceId),
          },
          renders: select.includes(fieldNames.Renders),
          resumes: select.includes(fieldNames.Resumes),
          rewinds: select.includes(fieldNames.Rewinds),
          skips: select.includes(fieldNames.Skips),
          starts: select.includes(fieldNames.Starts),
          thirdQuartiles: select.includes(fieldNames.ThirdQuartiles),
          unmutes: select.includes(fieldNames.Unmutes),
          userAgent: {
            browserFamily: select.includes(fieldNames.UserAgentBrowserFamily),
            browserVersion: select.includes(fieldNames.UserAgentBrowserVersion),
            browserVersionMajor: select.includes(
              fieldNames.UserAgentBrowserVersionMajor
            ),
            deviceBrand: select.includes(fieldNames.UserAgentDeviceBrand),
            deviceFamily: select.includes(fieldNames.UserAgentDeviceFamily),
            deviceModel: select.includes(fieldNames.UserAgentDeviceModel),
            osFamily: select.includes(fieldNames.UserAgentOsFamily),
            osVersion: select.includes(fieldNames.UserAgentOsVersion),
            osVersionMajor: select.includes(fieldNames.UserAgentOsVersionMajor),
          },
          views: select.includes(fieldNames.Views),
          visibility: select.includes(fieldNames.Visibility),
          visitorId: select.includes(fieldNames.VisitorId),
          visitorIp: select.includes(fieldNames.VisitorIp),
          width: select.includes(fieldNames.Width),
          winnerBidderId: select.includes(fieldNames.WinnerBidder),
          winnerBidderUserId: select.includes(fieldNames.WinnerBidderUserId),
          winnerPrice: select.includes(fieldNames.WinnerPrice),
          winnerPriceViewed: select.includes(fieldNames.WinnerPriceViewed),
        },
        fieldsGrouper: {
          createdAt: groupBy.includes(fieldNames.CreatedAt),
          createdDate: groupBy.includes(fieldNames.CreatedDate),
          currency: groupBy.includes(fieldNames.Currency),
          domainChain: groupBy.includes(fieldNames.DomainChain),
          geo: {
            city: groupBy.includes(fieldNames.GeoCity),
            country: groupBy.includes(fieldNames.GeoCountry),
          },
          height: groupBy.includes(fieldNames.Height),
          impressionType: groupBy.includes(fieldNames.ImpressionType),
          publisher: {
            domain: groupBy.includes(fieldNames.PublisherDomain),
            id: groupBy.includes(fieldNames.Publisher),
            place: groupBy.includes(fieldNames.PublisherPlaceId),
          },
          userAgent: {
            browserFamily: groupBy.includes(fieldNames.UserAgentBrowserFamily),
            browserVersion: groupBy.includes(
              fieldNames.UserAgentBrowserVersion
            ),
            browserVersionMajor: groupBy.includes(
              fieldNames.UserAgentBrowserVersionMajor
            ),
            deviceBrand: groupBy.includes(fieldNames.UserAgentDeviceBrand),
            deviceFamily: groupBy.includes(fieldNames.UserAgentDeviceFamily),
            deviceModel: groupBy.includes(fieldNames.UserAgentDeviceModel),
          },
          visibility: groupBy.includes(fieldNames.Visibility),
          visitorId: groupBy.includes(fieldNames.VisitorId),
          visitorIp: groupBy.includes(fieldNames.VisitorIp),
          width: groupBy.includes(fieldNames.Width),
          winnerBidderId: groupBy.includes(fieldNames.WinnerBidder),
          winnerBidderUserId: groupBy.includes(fieldNames.WinnerBidderUserId),
          winnerPrice: groupBy.includes(fieldNames.WinnerPrice),
          winnerPriceViewed: groupBy.includes(fieldNames.WinnerPriceViewed),
        },
        filter: {
          currencies: filter.get(FilterNames.Currencies) as
            | string[]
            | undefined,
          dateFrom: `${filter.get(FilterNames.DateFrom)}T00:00:00Z`,
          dateTo: `${filter.get(FilterNames.DateTo)}T00:00:00Z`,
          geoCityExclude: filter.get(FilterNames.GeoCityExclude) as
            | string[]
            | undefined,
          geoCityInclude: filter.get(FilterNames.GeoCityInclude) as
            | string[]
            | undefined,
          geoCountryExclude: filter.get(FilterNames.GeoCountryExclude) as
            | string[]
            | undefined,
          geoCountryInclude: filter.get(FilterNames.GeoCountryInclude) as
            | string[]
            | undefined,
          impressionTypes: filter.get(FilterNames.ImpressionTypes) as
            | string[]
            | undefined,
          publishersExclude: filter.get(FilterNames.PublishersExclude) as
            | number[]
            | undefined,
          publishersInclude: filter.get(FilterNames.PublishersInclude) as
            | number[]
            | undefined,
          publisherPlacesInclude: filter.get(
            FilterNames.PublishersPlacesInclude
          ) as number[] | undefined,
          userAgentBrowsers: filter.get(FilterNames.UserAgentBrowsers) as
            | string[]
            | undefined,
          userAgentDeviceBrands: filter.get(
            FilterNames.UserAgentDeviceBrands
          ) as string[] | undefined,
          userAgentDevices: filter.get(FilterNames.UserAgentDevices) as
            | string[]
            | undefined,
          userAgentOs: filter.get(FilterNames.UserAgentOs) as
            | string[]
            | undefined,
          visitorIdInclude: filter.get(FilterNames.VisitorIdInclude) as
            | string[]
            | undefined,
          visitorIpInclude: filter.get(FilterNames.VisitorIpInclude) as
            | string[]
            | undefined,
          winnerBidderIds: filter.get(FilterNames.WinnerBidderIds) as
            | number[]
            | undefined,
        },
      },
    };

    return clt.requestsPost(params);
  }
}
