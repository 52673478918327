
import { Vue } from "vue-class-component";
import { success } from "@/services/toaster";

export default class ApiKeyDialog extends Vue {
  dialog = false;
  apiKey = "";

  save() {
    localStorage.setItem("apiKey", this.apiKey);
    success(this, "API Key saved");
    this.apiKey = "";
    console.log(localStorage.getItem("apiKey"));
    this.dialog = false;
  }
}
