export enum FilterNames {
  Currencies = "currencies",
  DateFrom = "dateFrom",
  DateTo = "dateTo",
  GeoCityExclude = "geoCityExclude",
  GeoCityInclude = "geoCityInclude",
  GeoCountryExclude = "geoCountryExclude",
  GeoCountryInclude = "geoCountryInclude",
  ImpressionTypes = "impressionTypes",
  PublishersExclude = "publishersExclude",
  PublishersInclude = "publishersInclude",
  PublishersPlacesInclude = "publishersPlacesInclude",
  UserAgentBrowsers = "userAgentBrowsers",
  UserAgentDeviceBrands = "userAgentDeviceBrands",
  UserAgentDevices = "userAgentDevices",
  UserAgentOs = "userAgentOs",
  VisitorIdInclude = "visitorIdInclude",
  VisitorIpInclude = "visitorIpInclude",
  WinnerBidderIds = "winnerBidderIds",
}
