import { FieldNames } from "./fieldNames";

export const MetricsNames = [
  FieldNames.AcceptInvitation,
  FieldNames.BidderRequests,
  FieldNames.Clicks,
  FieldNames.ClientRequests,
  FieldNames.Closes,
  FieldNames.Collapses,
  FieldNames.Completes,
  FieldNames.ExitsFullscrine,
  FieldNames.Expands,
  FieldNames.FirstQuartiles,
  FieldNames.Fullscrines,
  FieldNames.Midpoints,
  FieldNames.Mutes,
  FieldNames.Pauses,
  FieldNames.Renders,
  FieldNames.Resumes,
  FieldNames.Rewinds,
  FieldNames.Skips,
  FieldNames.Starts,
  FieldNames.ThirdQuartiles,
  FieldNames.Unmutes,
  FieldNames.Views,
  FieldNames.WinnerPrice,
  FieldNames.WinnerPriceViewed,
];
