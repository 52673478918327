
import { Vue, Options } from "vue-class-component";
import { API as api } from "@/services/statistic-reader";
import { FieldNames } from "@/enums/fieldNames";
import { FilterNames } from "@/enums/filterNames";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { VDataTable } from "vuetify/labs/VDataTable";
import { OpenrtbStat } from "statistic-reader-client";
import { GroupByNames } from "@/enums/groupByNames";
import { Publishers } from "@/enums/publishers";
import { Bidders } from "@/enums/bidders";
import { error } from "@/services/toaster";
import { MetricsNames } from "@/enums/metricsNames";
import ApiKeyDialog from "./ApiKeyDialog.vue";
import Total from "./Total.vue";

interface DataHeader {
  title: string;
  key: string;
  sortable?: boolean;
  minWidth?: string;
}

@Options({
  components: {
    Datepicker,
    VDataTable,
    ApiKeyDialog,
    Total,
  },
})
export default class StatisticComponent extends Vue {
  //#region query
  metrics: FieldNames[] = [];
  groupBy: FieldNames[] = [];
  fieldNames: string[] = MetricsNames;
  groupByNames: string[] = GroupByNames;
  currency: string[] = ["RUB"];
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  countryExclude: string[] = [];
  countryInclude: string[] = [];
  cityExclude: string[] = [];
  cityInclude: string[] = [];
  impressionTypes: string[] = [];
  publisherInclude: number[] = [];
  publisherExclude: number[] = [];
  placeInclude: number[] = [];
  browser: string[] = [];
  deviceBrand: string[] = [];
  deviceModel: string[] = [];
  os: string[] = [];
  visitorId: string[] = [];
  visitorIp: string[] = [];
  winnerBidderId: number[] = [];
  //#endregion

  headers: DataHeader[] = [];
  items: object[] = [];
  totalItems: { key: string; value: number }[] = [];
  loading = false;

  publishers = Object.values(Publishers)
    .filter((x) => typeof x == "string")
    .map((x) => {
      return {
        name: x,
        value: Publishers[x as keyof typeof Publishers],
      };
    });

  bidders = Object.values(Bidders)
    .filter((x) => typeof x == "string")
    .map((x) => {
      return {
        name: x,
        value: Bidders[x as keyof typeof Bidders],
      };
    });

  clear(): void {
    this.metrics = [];
    this.groupBy = [];
    this.currency = ["RUB"];
    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.countryExclude = [];
    this.countryInclude = [];
    this.cityExclude = [];
    this.cityInclude = [];
    this.impressionTypes = [];
    this.publisherInclude = [];
    this.publisherExclude = [];
    this.browser = [];
    this.deviceBrand = [];
    this.deviceModel = [];
    this.os = [];
    this.visitorId = [];
    this.visitorIp = [];
    this.winnerBidderId = [];
  }

  loadReport(): void {
    const filter = new Map<
      FilterNames,
      string[] | string | number[] | number
    >();

    filter.set(FilterNames.DateFrom, this.dateFrom.toISOString().split("T")[0]);
    filter.set(FilterNames.DateTo, this.dateTo.toISOString().split("T")[0]);
    if (this.currency.length > 0) {
      filter.set(FilterNames.Currencies, this.currency);
    }

    if (this.countryExclude.length > 0) {
      filter.set(FilterNames.GeoCountryExclude, this.countryExclude);
    }

    if (this.countryInclude.length > 0) {
      filter.set(FilterNames.GeoCountryInclude, this.countryInclude);
    }

    if (this.cityExclude.length > 0) {
      filter.set(FilterNames.GeoCityExclude, this.cityExclude);
    }

    if (this.cityInclude.length > 0) {
      filter.set(FilterNames.GeoCityInclude, this.cityInclude);
    }

    if (this.impressionTypes.length > 0) {
      filter.set(FilterNames.ImpressionTypes, this.impressionTypes);
    }

    if (this.publisherInclude.length > 0) {
      filter.set(
        FilterNames.PublishersInclude,
        this.publisherInclude.map((i) => Number(i))
      );
    }

    if (this.publisherExclude.length > 0) {
      filter.set(
        FilterNames.PublishersExclude,
        this.publisherExclude.map((i) => Number(i))
      );
    }

    if (this.placeInclude.length > 0) {
      filter.set(
        FilterNames.PublishersPlacesInclude,
        this.placeInclude.map((i) => Number(i))
      );
    }

    if (this.browser.length > 0) {
      filter.set(FilterNames.UserAgentBrowsers, this.browser);
    }

    if (this.deviceBrand.length > 0) {
      filter.set(FilterNames.UserAgentDeviceBrands, this.deviceBrand);
    }

    if (this.deviceModel.length > 0) {
      filter.set(FilterNames.UserAgentDevices, this.deviceModel);
    }

    if (this.os.length > 0) {
      filter.set(FilterNames.UserAgentOs, this.os);
    }

    if (this.visitorId.length > 0) {
      filter.set(FilterNames.VisitorIdInclude, this.visitorId);
    }

    if (this.visitorIp.length > 0) {
      filter.set(FilterNames.VisitorIpInclude, this.visitorIp);
    }

    if (this.winnerBidderId.length > 0) {
      filter.set(
        FilterNames.WinnerBidderIds,
        this.winnerBidderId.map((i) => Number(i))
      );
    }

    if (this.impressionTypes.length > 0) {
      filter.set(FilterNames.ImpressionTypes, this.impressionTypes);
    }

    const select = [...this.metrics, ...this.groupBy];
    this.loading = true;
    api
      .loadReport(select, this.groupBy, filter)
      .finally(() => (this.loading = false))
      .catch((err) => {
        console.error(err);
        error(this, "Error while loading report");
      })
      .then((stat) => {
        this.items = [];
        this.headers = [];
        this.totalItems = [];
        if (stat == null) {
          return;
        }

        this.items = stat.map((i) => this.cutItem(i));
        this.headers = this.getHeaders();
        this.totalItems = this.getTotalItems(
          this.items as { [key: string]: number }[]
        );
      });
  }

  getHeaders(): DataHeader[] {
    const headers: DataHeader[] = [];
    if (this.metrics.includes(FieldNames.AcceptInvitation)) {
      headers.push({
        title: "Accept Invitation",
        key: FieldNames.AcceptInvitation,
      });
    }

    if (this.metrics.includes(FieldNames.BidderRequests)) {
      headers.push({
        title: "Bidder Requests",
        key: FieldNames.BidderRequests,
      });
    }

    if (this.metrics.includes(FieldNames.Clicks)) {
      headers.push({ title: "Clicks", key: FieldNames.Clicks });
    }

    if (this.metrics.includes(FieldNames.ClientRequests)) {
      headers.push({
        title: "Client Requests",
        key: FieldNames.ClientRequests,
      });
    }

    if (this.metrics.includes(FieldNames.Closes)) {
      headers.push({ title: "Closes", key: FieldNames.Closes });
    }

    if (this.metrics.includes(FieldNames.Collapses)) {
      headers.push({ title: "Collapses", key: FieldNames.Collapses });
    }

    if (this.metrics.includes(FieldNames.Completes)) {
      headers.push({ title: "Completes", key: FieldNames.Completes });
    }

    if (this.groupBy.includes(FieldNames.CreatedAt)) {
      headers.push({ title: "Created At", key: FieldNames.CreatedAt });
    }

    if (this.groupBy.includes(FieldNames.CreatedDate)) {
      headers.push({ title: "Created Date", key: FieldNames.CreatedDate });
    }

    if (this.groupBy.includes(FieldNames.Currency)) {
      headers.push({ title: "Currency", key: FieldNames.Currency });
    }

    if (this.groupBy.includes(FieldNames.DomainChain)) {
      headers.push({ title: "Domain Chain", key: FieldNames.DomainChain });
    }

    if (this.metrics.includes(FieldNames.ExitsFullscrine)) {
      headers.push({
        title: "Exist Fullscrine",
        key: FieldNames.ExitsFullscrine,
      });
    }

    if (this.metrics.includes(FieldNames.Expands)) {
      headers.push({ title: "Expands", key: FieldNames.Expands });
    }

    if (this.metrics.includes(FieldNames.FirstQuartiles)) {
      headers.push({
        title: "First Quartiles",
        key: FieldNames.FirstQuartiles,
      });
    }

    if (this.metrics.includes(FieldNames.Fullscrines)) {
      headers.push({ title: "Fullscrines", key: FieldNames.Fullscrines });
    }

    if (this.groupBy.includes(FieldNames.GeoCity)) {
      headers.push({ title: "Geo City", key: FieldNames.GeoCity });
    }

    if (this.groupBy.includes(FieldNames.GeoCountry)) {
      headers.push({ title: "Geo Country", key: FieldNames.GeoCountry });
    }

    if (this.groupBy.includes(FieldNames.Height)) {
      headers.push({ title: "Height", key: FieldNames.Height });
    }

    if (this.groupBy.includes(FieldNames.ImpressionType)) {
      headers.push({
        title: "Impression Type",
        key: FieldNames.ImpressionType,
      });
    }

    if (this.metrics.includes(FieldNames.Midpoints)) {
      headers.push({ title: "Midpoints", key: FieldNames.Midpoints });
    }

    if (this.metrics.includes(FieldNames.Mutes)) {
      headers.push({ title: "Mutes", key: FieldNames.Mutes });
    }

    if (this.metrics.includes(FieldNames.Pauses)) {
      headers.push({ title: "Pauses", key: FieldNames.Pauses });
    }

    if (this.groupBy.includes(FieldNames.PublisherDomain)) {
      headers.push({
        title: "Publisher Domain",
        key: FieldNames.PublisherDomain,
      });
    }

    if (this.groupBy.includes(FieldNames.Publisher)) {
      headers.push({ title: "Publisher", key: FieldNames.Publisher });
    }

    if (this.groupBy.includes(FieldNames.PublisherPlaceId)) {
      headers.push({ title: "Place Id", key: FieldNames.PublisherPlaceId });
    }

    if (this.metrics.includes(FieldNames.Renders)) {
      headers.push({ title: "Renders", key: FieldNames.Renders });
    }

    if (this.metrics.includes(FieldNames.Resumes)) {
      headers.push({ title: "Resumes", key: FieldNames.Resumes });
    }

    if (this.metrics.includes(FieldNames.Rewinds)) {
      headers.push({ title: "Rewinds", key: FieldNames.Rewinds });
    }

    if (this.metrics.includes(FieldNames.Skips)) {
      headers.push({ title: "Skips", key: FieldNames.Skips });
    }

    if (this.metrics.includes(FieldNames.Starts)) {
      headers.push({ title: "Starts", key: FieldNames.Starts });
    }

    if (this.metrics.includes(FieldNames.ThirdQuartiles)) {
      headers.push({
        title: "Third Quartiles",
        key: FieldNames.ThirdQuartiles,
      });
    }

    if (this.metrics.includes(FieldNames.Unmutes)) {
      headers.push({ title: "Unmutes", key: FieldNames.Unmutes });
    }

    if (this.groupBy.includes(FieldNames.UserAgentBrowserFamily)) {
      headers.push({
        title: "Browser Family",
        key: FieldNames.UserAgentBrowserFamily,
      });
    }

    if (this.groupBy.includes(FieldNames.UserAgentBrowserVersion)) {
      headers.push({
        title: "Browser Version",
        key: FieldNames.UserAgentBrowserVersion,
      });
    }

    if (this.groupBy.includes(FieldNames.UserAgentBrowserVersionMajor)) {
      headers.push({
        title: "Browser Version Major",
        key: FieldNames.UserAgentBrowserVersionMajor,
      });
    }

    if (this.groupBy.includes(FieldNames.UserAgentDeviceBrand)) {
      headers.push({
        title: "Device Brand",
        key: FieldNames.UserAgentDeviceBrand,
      });
    }

    if (this.groupBy.includes(FieldNames.UserAgentDeviceFamily)) {
      headers.push({
        title: "Device Family",
        key: FieldNames.UserAgentDeviceFamily,
      });
    }

    if (this.groupBy.includes(FieldNames.UserAgentDeviceModel)) {
      headers.push({
        title: "Device Model",
        key: FieldNames.UserAgentDeviceModel,
      });
    }

    if (this.groupBy.includes(FieldNames.UserAgentOsFamily)) {
      headers.push({ title: "OS Family", key: FieldNames.UserAgentOsFamily });
    }

    if (this.groupBy.includes(FieldNames.UserAgentOsVersion)) {
      headers.push({ title: "OS Version", key: FieldNames.UserAgentOsVersion });
    }

    if (this.groupBy.includes(FieldNames.UserAgentOsVersionMajor)) {
      headers.push({
        title: "OS Version Major",
        key: FieldNames.UserAgentOsVersionMajor,
      });
    }

    if (this.metrics.includes(FieldNames.Views)) {
      headers.push({ title: "Views", key: FieldNames.Views });
    }

    if (this.groupBy.includes(FieldNames.Visibility)) {
      headers.push({ title: "Visibility", key: FieldNames.Visibility });
    }

    if (this.groupBy.includes(FieldNames.VisitorId)) {
      headers.push({ title: "Visitor ID", key: FieldNames.VisitorId });
    }

    if (this.groupBy.includes(FieldNames.VisitorIp)) {
      headers.push({ title: "Visitor IP", key: FieldNames.VisitorIp });
    }

    if (this.groupBy.includes(FieldNames.Width)) {
      headers.push({ title: "Width", key: FieldNames.Width });
    }

    if (this.groupBy.includes(FieldNames.WinnerBidder)) {
      headers.push({ title: "Winner Bidder", key: FieldNames.WinnerBidder });
    }

    if (this.groupBy.includes(FieldNames.WinnerBidderUserId)) {
      headers.push({
        title: "WInner Bidder User ID",
        key: FieldNames.WinnerBidderUserId,
      });
    }

    if (this.metrics.includes(FieldNames.WinnerPrice)) {
      headers.push({ title: "Winner Price", key: FieldNames.WinnerPrice });
    }

    if (this.metrics.includes(FieldNames.WinnerPriceViewed)) {
      headers.push({
        title: "Winner Price Viewed",
        key: FieldNames.WinnerPriceViewed,
      });
    }

    return headers;
  }

  cutItem(src: OpenrtbStat): object {
    const item: { [key: string]: unknown } = {};
    if (this.metrics.includes(FieldNames.AcceptInvitation)) {
      item[FieldNames.AcceptInvitation] = src.acceptsInvitation;
    }

    if (this.metrics.includes(FieldNames.BidderRequests)) {
      item[FieldNames.BidderRequests] = src.bidderRequests;
    }

    if (this.metrics.includes(FieldNames.Clicks)) {
      item[FieldNames.Clicks] = src.clicks;
    }

    if (this.metrics.includes(FieldNames.ClientRequests)) {
      item[FieldNames.ClientRequests] = src.clientRequests;
    }

    if (this.metrics.includes(FieldNames.Closes)) {
      item[FieldNames.Closes] = src.closes;
    }

    if (this.metrics.includes(FieldNames.Collapses)) {
      item[FieldNames.Collapses] = src.collapses;
    }

    if (this.metrics.includes(FieldNames.Completes)) {
      item[FieldNames.Completes] = src.completes;
    }

    if (this.groupBy.includes(FieldNames.CreatedAt)) {
      item[FieldNames.CreatedAt] = src.createdAt as string;
    }

    if (this.groupBy.includes(FieldNames.CreatedDate)) {
      item[FieldNames.CreatedDate] = src.createdDate;
    }

    if (this.groupBy.includes(FieldNames.Currency)) {
      item[FieldNames.Currency] = src.currency;
    }

    if (this.groupBy.includes(FieldNames.DomainChain)) {
      item[FieldNames.DomainChain] = src.domainChain;
    }

    if (this.metrics.includes(FieldNames.ExitsFullscrine)) {
      item[FieldNames.ExitsFullscrine] = src.exitsFullscrine;
    }

    if (this.metrics.includes(FieldNames.Expands)) {
      item[FieldNames.Expands] = src.expands;
    }

    if (this.metrics.includes(FieldNames.FirstQuartiles)) {
      item[FieldNames.FirstQuartiles] = src.firstQuartiles;
    }

    if (this.metrics.includes(FieldNames.Fullscrines)) {
      item[FieldNames.Fullscrines] = src.fullscrines;
    }

    if (this.groupBy.includes(FieldNames.GeoCity)) {
      item[FieldNames.GeoCity] = src.geo?.geoCity;
    }

    if (this.groupBy.includes(FieldNames.GeoCountry)) {
      item[FieldNames.GeoCountry] = src.geo?.geoCountry;
    }

    if (this.groupBy.includes(FieldNames.Height)) {
      item[FieldNames.Height] = src.height;
    }

    if (this.groupBy.includes(FieldNames.ImpressionType)) {
      item[FieldNames.ImpressionType] = src.impressionType;
    }

    if (this.metrics.includes(FieldNames.Midpoints)) {
      item[FieldNames.Midpoints] = src.midpoints;
    }

    if (this.metrics.includes(FieldNames.Mutes)) {
      item[FieldNames.Mutes] = src.mutes;
    }

    if (this.metrics.includes(FieldNames.Pauses)) {
      item[FieldNames.Pauses] = src.pauses;
    }

    if (this.groupBy.includes(FieldNames.PublisherDomain)) {
      item[FieldNames.PublisherDomain] = src.publisher?.domain;
    }

    if (this.groupBy.includes(FieldNames.Publisher)) {
      item[FieldNames.Publisher] = Publishers[src.publisher?.id ?? 0];
    }

    if (this.groupBy.includes(FieldNames.PublisherPlaceId)) {
      item[FieldNames.PublisherPlaceId] = src.publisher?.placeId;
    }

    if (this.metrics.includes(FieldNames.Renders)) {
      item[FieldNames.Renders] = src.renders;
    }

    if (this.metrics.includes(FieldNames.Resumes)) {
      item[FieldNames.Resumes] = src.resumes;
    }

    if (this.metrics.includes(FieldNames.Rewinds)) {
      item[FieldNames.Rewinds] = src.rewinds;
    }

    if (this.metrics.includes(FieldNames.Skips)) {
      item[FieldNames.Skips] = src.skips;
    }

    if (this.metrics.includes(FieldNames.Starts)) {
      item[FieldNames.Starts] = src.starts;
    }

    if (this.metrics.includes(FieldNames.ThirdQuartiles)) {
      item[FieldNames.ThirdQuartiles] = src.thirdQuartiles;
    }

    if (this.metrics.includes(FieldNames.Unmutes)) {
      item[FieldNames.Unmutes] = src.unmutes;
    }

    if (this.groupBy.includes(FieldNames.UserAgentBrowserFamily)) {
      item[FieldNames.UserAgentBrowserFamily] = src.userAgent?.browserFamily;
    }

    if (this.groupBy.includes(FieldNames.UserAgentBrowserVersion)) {
      item[FieldNames.UserAgentBrowserVersion] = src.userAgent?.browserVersion;
    }

    if (this.groupBy.includes(FieldNames.UserAgentBrowserVersionMajor)) {
      item[FieldNames.UserAgentBrowserVersionMajor] =
        src.userAgent?.browserVersionMajor;
    }

    if (this.groupBy.includes(FieldNames.UserAgentDeviceBrand)) {
      item[FieldNames.UserAgentDeviceBrand] = src.userAgent?.deviceBrand;
    }

    if (this.groupBy.includes(FieldNames.UserAgentDeviceFamily)) {
      item[FieldNames.UserAgentDeviceFamily] = src.userAgent?.deviceFamily;
    }

    if (this.groupBy.includes(FieldNames.UserAgentDeviceModel)) {
      item[FieldNames.UserAgentDeviceModel] = src.userAgent?.deviceModel;
    }

    if (this.groupBy.includes(FieldNames.UserAgentOsFamily)) {
      item[FieldNames.UserAgentOsFamily] = src.userAgent?.osFamily;
    }

    if (this.groupBy.includes(FieldNames.UserAgentOsVersion)) {
      item[FieldNames.UserAgentOsVersion] = src.userAgent?.osVersion;
    }

    if (this.groupBy.includes(FieldNames.UserAgentOsVersionMajor)) {
      item[FieldNames.UserAgentOsVersionMajor] = src.userAgent?.osVersionMajor;
    }

    if (this.metrics.includes(FieldNames.Views)) {
      item[FieldNames.Views] = src.views;
    }

    if (this.groupBy.includes(FieldNames.Visibility)) {
      item[FieldNames.Visibility] = src.visibility;
    }

    if (this.groupBy.includes(FieldNames.VisitorId)) {
      item[FieldNames.VisitorId] = src.visitorId;
    }

    if (this.groupBy.includes(FieldNames.VisitorIp)) {
      item[FieldNames.VisitorIp] = src.visitorIp;
    }

    if (this.groupBy.includes(FieldNames.Width)) {
      item[FieldNames.Width] = src.width;
    }

    if (this.groupBy.includes(FieldNames.WinnerBidder)) {
      const id = src.winnerBidderId;
      item[FieldNames.WinnerBidder] =
        id != 0 ? Bidders[src.winnerBidderId ?? 0] : "[No Winner]";
    }

    if (this.groupBy.includes(FieldNames.WinnerBidderUserId)) {
      item[FieldNames.WinnerBidderUserId] = src.winnerBidderUid;
    }

    if (this.metrics.includes(FieldNames.WinnerPrice)) {
      item[FieldNames.WinnerPrice] = Number(src.winnerPrice);
    }

    if (this.metrics.includes(FieldNames.WinnerPriceViewed)) {
      item[FieldNames.WinnerPriceViewed] = Number(src.winnerPriceViewed);
    }

    return item;
  }

  getTotalItems(
    items: { [key: string]: number }[]
  ): { key: string; value: number }[] {
    let totalItems = [];
    for (let name of MetricsNames) {
      if (name in items[0]) {
        totalItems.push({
          key: name,
          value: items
            .map((i) => i[name])
            .reduce((sum: number, i: number) => sum + i, 0),
        });
      }
    }

    return totalItems;
  }
}
