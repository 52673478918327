import Toaster from "@meforma/vue-toaster";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Vue } from "vue-class-component";

export default Toaster;

/**
 *
 * @param {Vue} component
 * @param {String} msg
 * @param {Object} options
 */
export function success(component, msg, options = {}) {
  component.$toast.success(msg, options);
}

/**
 *
 * @param {Vue} component
 * @param {String} msg
 * @param {Object} options
 */
export function show(component, msg, options = {}) {
  component.$toast.show(msg, options);
}

/**
 *
 * @param {Vue} component
 * @param {String} msg
 * @param {Object} options
 */
export function error(component, msg, options = {}) {
  component.$toast.error(msg, options);
}

/**
 *
 * @param {Vue} component
 * @param {String} msg
 * @param {Object} options
 */
export function warning(component, msg, options = {}) {
  component.$toast.warning(msg, options);
}

/**
 *
 * @param {Vue} component
 * @param {String} msg
 * @param {Object} options
 */
export function info(component, msg, options = {}) {
  component.$toast.info(msg, options);
}
