export enum FieldNames {
  AcceptInvitation = "acceptsInvitation",
  BidderRequests = "bidderRequests",
  Clicks = "clicks",
  ClientRequests = "clientRequests",
  Closes = "closes",
  Collapses = "collapses",
  Completes = "completes",
  CreatedAt = "createdAt",
  CreatedDate = "createdDate",
  Currency = "currency",
  DomainChain = "domainChain",
  ExitsFullscrine = "exitsFullscrine",
  Expands = "expands",
  FirstQuartiles = "firstQuartiles",
  Fullscrines = "fullscrines",
  GeoCity = "geo_city",
  GeoCountry = "geo_country",
  Height = "height",
  ImpressionType = "impressionType",
  Midpoints = "midpoints",
  Mutes = "mutes",
  Pauses = "pauses",
  PublisherDomain = "publisher_domain",
  Publisher = "publisher",
  PublisherPlaceId = "publisher_placeId",
  Renders = "renders",
  Resumes = "resumes",
  Rewinds = "rewinds",
  Skips = "skips",
  Starts = "starts",
  ThirdQuartiles = "thirdQuartiles",
  Unmutes = "unmutes",
  UserAgentBrowserFamily = "userAgent_browserFamily",
  UserAgentBrowserVersion = "userAgent_browserVersion",
  UserAgentBrowserVersionMajor = "userAgent_browserVersionMajor",
  UserAgentDeviceBrand = "userAgent_deviceBrand",
  UserAgentDeviceFamily = "userAgent_deviceFamily",
  UserAgentDeviceModel = "userAgent_deviceModel",
  UserAgentOsFamily = "userAgent_osFamily",
  UserAgentOsVersion = "userAgent_osVersion",
  UserAgentOsVersionMajor = "userAgent_osVersionMajor",
  Views = "views",
  Visibility = "visibility",
  VisitorId = "visitorId",
  VisitorIp = "visitorIp",
  Width = "width",
  WinnerBidder = "winnerBidder",
  WinnerBidderUserId = "winnerBidderUserId",
  WinnerPrice = "winnerPrice",
  WinnerPriceViewed = "winnerPriceViewed",
}
